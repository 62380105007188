import { Helmet } from "react-helmet-async";
import "../css/Servers.css";
import { useEffect, useState } from "react";
import Logo from "../images/vanarchylogo.png";

const Servers = () => {
  const [servercount, setServerCount] = useState(0);
  const [proxyserver, setProxyServer] = useState(null);
  const [shopserver, setShopServer] = useState(null);
  const [survivalserver, setSurvivalServer] = useState(null);

  useEffect(() => {
    if (proxyserver) {
      setServerCount(servercount + 1);
      return;
    }
    var url = "https://api.minetools.eu/ping/play.vanarchy.uk/25565";
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var response = JSON.parse(xhr.responseText);
        console.log(response.players)
        setProxyServer(response);
      } else if (xhr.status !== 200) {
        console.error("Error: Request failed with status", xhr.status);
      }
    };
    xhr.send();
  }, [proxyserver]);

  useEffect(() => {
    if (shopserver) {
      setServerCount(servercount + 1);
      return;
    }
    var url = "https://api.minetools.eu/ping/shop.vanarchy.uk/25566";
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var response = JSON.parse(xhr.responseText);
        setShopServer(response);
      } else if (xhr.status !== 200) {
        console.error("Error: Request failed with status", xhr.status);
      }
    };
    xhr.send();
  }, [shopserver]);

  useEffect(() => {
    if (survivalserver) {
      setServerCount(servercount + 1);
      return;
    }
    var url = "https://api.minetools.eu/ping/survival.vanarchy.uk/25569";
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var response = JSON.parse(xhr.responseText);
        setSurvivalServer(response);
      } else if (xhr.status !== 200) {
        console.error("Error: Request failed with status", xhr.status);
      }
    };
    xhr.send();
  }, [survivalserver]);

  return (
    <div className="serverspage">
      <Helmet>
        <title>Servers | Vanarchy</title>
      </Helmet>
      <div className="info">
        <h1>Servers</h1>
        <p>Server page body content</p>
      </div>
      <div className="serverscontainer">
        <div className="servers">
          <div className="serverbox">
            <img src={Logo} alt="" />
            <div className="serverinfo">
              <h1>Proxy</h1>
              <div className="serverstats">
                <p>play.vanarchy.uk</p>
                <p>
                  {proxyserver ? (
                    proxyserver.players.online +
                    "/" +
                    proxyserver.players.max +
                    " players online"
                  ) : (
                    <div className="loader">
                      <div className="loading-spinner">
                        <svg
                          className="loading-spinner__circle-svg"
                          viewBox="25 25 50 50"
                        >
                          <circle
                            className="loading-spinner__circle-stroke"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
          
          {/*<div className="serverbox">
            <img src={Logo} alt="" />
            <div className="serverinfo">
              <h1>Shop</h1>
              <p>shop.vanarchy.uk</p>
              <div className="serverstats">
                <p>
                  {shopserver ? (
                    shopserver.players.online +
                    "/" +
                    shopserver.players.max +
                    " players online"
                  ) : (
                    <div className="loader">
                      <div className="loading-spinner">
                        <svg
                          className="loading-spinner__circle-svg"
                          viewBox="25 25 50 50"
                        >
                          <circle
                            className="loading-spinner__circle-stroke"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="serverbox">
            <img src={Logo} alt="" />
            <div className="serverinfo">
              <h1>Survival</h1>
              <div className="serverstats">
                <p>survival.vanarchy.uk</p>
                <p>
                  {survivalserver ? (
                    survivalserver.players.online +
                    "/" +
                    survivalserver.players.max +
                    " players online"
                  ) : (
                    <div className="loader">
                      <div className="loading-spinner">
                        <svg
                          className="loading-spinner__circle-svg"
                          viewBox="25 25 50 50"
                        >
                          <circle
                            className="loading-spinner__circle-stroke"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </p>
              </div>
            </div>
                  </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Servers;
