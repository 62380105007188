import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Discord = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const previousURL = document.referrer;

    console.log(previousURL)
    
    if (previousURL.includes("localhost")) {
      window.open("https://discord.gg/hBTNEdZgWm")
      navigate(-1);
    } else {
      navigate("/");
      window.location.href = "https://discord.gg/hBTNEdZgWm";
    }
  }, []);

  return (
    <div className="discord">
      <p>
        If You Have Not Been Redirected{" "}
        <a className="links" href="https://discord.gg/hBTNEdZgWm">
          Click Here
        </a>
      </p>
    </div>
  );
};

export default Discord;
