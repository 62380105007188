import { Helmet } from "react-helmet-async";
import "../css/Rules.css";
import { useState } from "react";
import GeneralRules from "../components/GeneralRules";
import MinecratRules from "../components/MinecraftRules";
import ClientRules from "../components/ClientRules";
import PunishmentRules from "../components/Punishments";
import { useNavigate, useLocation  } from "react-router-dom";

const Rules = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fragmentIdentifier = location.hash;
  const [currentTab, setCurrentTab] = useState(fragmentIdentifier || "#general");

  console.log(fragmentIdentifier)

  const handleTabChange = (e) => {
    setCurrentTab("#" + e.target.id);
    navigate("/rules#" + e.target.id);
  };

  return (
    <div className="rules">
      <Helmet>
        <title>Rules | Vanarchy</title>
      </Helmet>
      <header>Rules</header>
      <div className="rulesnav">
        <ul>
          <li id="general" className={currentTab === "#general" ? "active" : ""} onClick={handleTabChange}>
            General
          </li>
          <li id="minecraft" className={currentTab === "#minecraft" ? "active" : ""} onClick={handleTabChange}>
            Minecraft
          </li>
          <li id="client" className={currentTab === "#client" ? "active" : ""} onClick={handleTabChange}>
            Client
          </li>
          <li id="punishments" className={currentTab === "#punishments" ? "active" : ""} onClick={handleTabChange}>
            Punishments
          </li>
        </ul>
      </div>
      <div className="rulescontent">
        {currentTab === "#general" && <GeneralRules />}
        {currentTab === "#minecraft" && <MinecratRules />}
        {currentTab === "#client" && <ClientRules />}
        {currentTab === "#punishments" && <PunishmentRules />}
      </div>
    </div>
  );
};

export default Rules;
