import { Helmet } from "react-helmet-async";
import Video from "../videos/VanarchyFastCrystals.mp4";

const FastCrystalsVid = () => {
  return (
    <div
      className="fastcrystals"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>Fast Crystals | Vanarchy</title>
        <meta name="description" content="Vanarchy Network Fast Crystals." />
        <meta content="Embed Title" property="og:title" />
        <meta content="Site Description" property="og:description" />
        <meta
          content="https://vanarchy.uk/fast-crystals"
          property="og:url"
        />
        <meta content="https://vanarchy.uk/static/media/VanarchyFastCrystals.472f39b4685bf3d3cd74.mp4" property="og:image" />
        <meta content="#8000ff" data-react-helmet="true" name="theme-color" />
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
      <video src={Video} width="85%" controls autoPlay></video>
    </div>
  );
};

export default FastCrystalsVid;
