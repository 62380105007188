import { Helmet } from "react-helmet-async";

const GeneralRules = () => {
  return (
    <div className="generalrules">
      <Helmet>
        <title>General Rules | Vanarchy</title>
      </Helmet>
      <ul>
        <li>
          Follow the discord <a className="links" href="https://discord.com/terms" target="_blank">Terms of Service</a>
        </li>
        <li>Impersonation of players or staff is not allowed</li>
        <li>No harrassing, sexism, racism or hate speech</li>
        <li>
          DDOSing, swatting and doxing or conspiring to do so is not allowed
        </li>
        <li>
          Bullying, harrassing, encouraging self-hard or threats are not
          tolerated
        </li>
        <li>No NSFW content</li>
        <ul>
          <li>This includes text, images or links the feature sex, nudity, racicm, homophobia, violence and any other graphically disturbing content</li>
        </ul>
        <li>Advertising of any sort unless in correct channels</li>
        <li>You must not advertise:</li>
        <ul>
          <li>Cross-server trading</li>
          <li>Other servers</li>
          <li>Disallowed client mods</li>
        </ul>
        <li>Do not spam</li>
        <li>Do not use automated messages</li>
        <li>You cannot appeal on behalf of others</li>
        <li>Do not ping others unless for good reason</li>
        <li>Charging back purchases on the webstore will result in a ban</li>
        <br />
        <p>This List Is Not Exhaustive, <span className="bold">Use Commond Sense</span></p>
      </ul>
    </div>
  );
};

export default GeneralRules;
