import { Helmet } from "react-helmet-async";
import "../css/Status.css";

const Status = () => {
  return (
    <div className="status">
      <Helmet>
        <title>Status | Vanarchy</title>
      </Helmet>
      <iframe className="statuspageiframe" src="https://vanarchy.statuspage.io/"></iframe>
    </div>
  );
};

export default Status;
