import { React, useState } from "react";
import Logo from "../images/vanarchylogo.png";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = (props) => {
  const navigate = useNavigate();

  const [dropdown, setDropdown] = useState(false);

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleDropDownClick = () => {
    if (dropdown) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  return (
    <nav>
      <div className="logo" onClick={handleLogoClick}>
        <img className="logoimg" src={Logo} alt="" />
        <h1 className="logotext">Vanarchy</h1>
      </div>
      <ul>
        <li className="navbarhome">
          <NavLink to="/">Home</NavLink>
        </li>
        <li className="navbarfaq">
          <NavLink to="/faq">Faq</NavLink>
        </li>
        <li className="navbarrules">
          <NavLink to="/rules">Rules</NavLink>
        </li>
        <li className="navbarservers">
          <NavLink to="/servers">Servers</NavLink>
        </li>
        <li className="navbarstatus">
          <NavLink to="/status">Status</NavLink>
        </li>
        <li className="navbardiscord">
          <a href="https://discord.com/invite/hBTNEdZgWm" target="_blank">
            Discord
          </a>
        </li>
        <li className="navbarstore">
          <a href="https://vanarchymc.tebex.io/" target="_blank">
            Store
          </a>
        </li>
        <div className="dropdown">
          <button className={dropdown === true ? "dropbtn active" : "dropbtn"}>
            <svg
              height="32"
              viewBox="0 0 48 48"
              width="32"
              xmlns="http://www.w3.org/2000/svg"
              style={{ display: "flex", justifyContent: "center" }}
              onClick={handleDropDownClick}
            >
              <path
                className="dropdownicon"
                d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"
                fill="#ddd"
              />
              <path d="M0-.75h48v48h-48z" fill="none" />
            </svg>
          </button>
          <div
            className={
              dropdown === true ? "dropdown-content active" : "dropdown-content"
            }
          >
            <ul>
              <li className="dropdowntext dropdowntexthome">
                <NavLink to="/" onMouseUp={handleDropDownClick}>
                  Home
                </NavLink>
              </li>
              <li className="dropdowntext dropdowntextfaq">
                <NavLink to="/faq" onMouseUp={handleDropDownClick}>
                  Faq
                </NavLink>
              </li>
              <li className="dropdowntext dropdowntextrules">
                <NavLink to="/rules" onMouseUp={handleDropDownClick}>
                  Rules
                </NavLink>
              </li>
              <li className="dropdowntext dropdowntextservers">
                <NavLink to="/servers" onMouseUp={handleDropDownClick}>
                  Servers
                </NavLink>
              </li>
              <li className="dropdowntext dropdowntextstatus">
                <NavLink to="/status" onMouseUp={handleDropDownClick}>
                  Status
                </NavLink>
              </li>
              <li className="dropdowntext dropdowntextdiscord">
                <a href="https://discord.com/invite/hBTNEdZgWm" target="_blank">
                  Discord
                </a>
              </li>
              <li className="dropdowntext dropdowntextstore">
                <a href="https://vanarchymc.tebex.io/" target="_blank">
                  Store
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;
