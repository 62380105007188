import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "../css/404.css";

const NotFound = () => {
  return (
    <div className="notfoundpage">
      <Helmet>
        <title>404 | Page Not Found</title>
      </Helmet>
      <h1>404</h1>
      <p>What you are looking for could not be found!</p>
      <Link to="/" className="gohome"><div className="gohomeinner"><div className="gohometext">Go Home</div></div></Link>
    </div>
  );
};

export default NotFound;
