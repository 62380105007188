import { Helmet } from "react-helmet-async";

const PunishmentRules = () => {
  return (
    <div className="punishmentrules">
      <Helmet>
        <title>Punishment Rules | Vanarchy</title>
      </Helmet>
      <ul>
        <li>
          Bans will start at 7 days but could be longer or shorted depending on
          a number of reasons.
        </li>
        <li>
          Mutes will be given out if you are found to be breaking chat rules and
          trying to evade can result in a much longer or permanent mute.
        </li>
        <li>Trying to evade a ban will result in a harsher punishment or a permanent ban.</li>
      </ul>
    </div>
  );
};

export default PunishmentRules;
