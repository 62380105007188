import {React} from "react";
import "./css/App.css";
import Navbar from "./components/navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home";
import NotFound from "./pages/404";
import Rules from "./pages/Rules";
import FastCrystalsVid from "./pages/Fast-Crystals";
import Discord from "./pages/Discord";
import Store from "./pages/Store";
import Servers from "./pages/Servers";
import Faq from "./pages/Faq";
import Status from "./pages/Status";

const helmetContext = {};

function App() {

  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <div className="App">
          <Navbar/>
          <div className="content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/faq" element={<Faq />} />
              <Route exact path="/rules" element={<Rules />} />
              <Route exact path="/servers" element={<Servers />}/>
              <Route exact path="/status" element={<Status />}/>
              <Route exact path="/discord" element={<Discord />} />
              <Route exact path="/store" element={<Store />} />
              <Route exact path="/fast-crystals" element={<FastCrystalsVid />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
