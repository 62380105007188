import { Helmet } from "react-helmet-async";

const Faq = () => {
  return (
    <div className="faq">
      <Helmet>
        <title>Faq | Vanarchy</title>
      </Helmet>
      <h1>Faq</h1>
      <p>Faq page body content</p>
    </div>
  );
};

export default Faq;
