import { Helmet } from "react-helmet-async";

const ClientRules = () => {
    return (
      <div className="clientrules">
        <Helmet>
          <title>Client Rules | Vanarchy</title>
        </Helmet>
        <ul>
          Allowed Mods:
          <li>Performance mods</li>
          <li>Freelook and zoom</li>
          <li>Litematica but not using the auto build mode</li>
          <li>Cosmetic mods</li>
          <li>Replay mods</li>
          <li>Mini map that doesn't show entities or players</li>
          <li>HUD mods the only show fair information</li>
          <ul>
            <li>Inventory HUD, Armour HUD, Potion HUD, Coordinates, etc</li>
          </ul>
          <li>Shulker tooltip/peak</li>
          <li>Auto clickers:</li>
          <ul>
            <li>Can only be used for afking farms</li>
            <li>Using fabric auto clicker with mob mode will cause you to be banned</li>
          </ul>
          <br />
          Disallowed Mods:
          <li>Anything that gives an unfair advantage</li>
          <li>Hacked clients/mods</li>
          <li>Health indicators</li>
          <li>Seed crackers</li>
          <li>Any mod that automatically moves items in your inventory including but not limited to:</li>
          <ul>
            <li>Auto totem</li>
            <li>Auto armour</li>
          </ul>
          <li>X-ray</li>
          <li>Freecam</li>
          <li>VR</li>
          <li>Radar</li>
          <li>Macros</li>
          <br />
          <br />
          If you are unsure if the mod you are using is allowed or not, please contact a member of staff before using it.
        </ul>
      </div>
    );
  };
  
  export default ClientRules;