import { Helmet } from "react-helmet-async";

const MinecratRules = () => {
    return (
      <div className="minecraftrules">
        <Helmet>
          <title>Minecraft Rules | Vanarchy</title>
        </Helmet>
        <ul>
            <li>No hacking or cheating in any way or using anything to gain a major advantage over the vanilla client</li>
            <li>No causing intentional lag or trying to use up large amounts of server resources</li>
            <li>No trying to obtain, distribute or use the seed in any way</li>
            <li>No trading or discussing about Vanarchy in-game items for items or ranks on any other server</li>
            <li>Don't do any type of glitch banning, including but not limited to:</li>
            <ul>
              <li>Book banning</li>
              <li>Chunk banning</li>
            </ul>
            <li>You cannot use VPNs to connect to the server, if you try to it may cause you to be false banned/punished</li>
            <li>Don't abuse bugs</li>
            <li>Make sure to use only allowed client mods</li>
        </ul>
      </div>
    );
  };
  
  export default MinecratRules;